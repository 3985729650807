import { useCallback, useEffect, useState } from 'react'
import { _ } from '../common/lodash'

function useLocalStorage<T>(key: string, defaultValue?: T) {
    const [state, setStateRaw] = useState<T>(() => {
        const item = window.localStorage.getItem(key)
        if (_.isNull(item)) {
            return defaultValue
        }
        return JSON.parse(item)
    })

    const setState = useCallback(
        (value: T) => {
            const newValue = JSON.stringify(value)
            window.localStorage.setItem(key, newValue)
            setStateRaw((oldValue) => {
                window.dispatchEvent(
                    new StorageEvent('storage', {
                        storageArea: window.localStorage,
                        url: window.location.href,
                        key,
                        newValue,
                        oldValue: JSON.stringify(oldValue),
                    })
                )
                return value
            })
        },
        [key]
    )

    useEffect(() => {
        const handleStorageChange = (e: StorageEvent) => {
            if (e.key !== key || e.storageArea !== window.localStorage) return
            setStateRaw((old) => {
                if (e.newValue !== JSON.stringify(old)) {
                    return e.newValue ? JSON.parse(e.newValue) : undefined
                }
            })
        }

        window.addEventListener('storage', handleStorageChange)
        return () => window.removeEventListener('storage', handleStorageChange)
    }, [key])

    return [state, setState] as [T, (v: T) => void]
}

export const useLocalStorages = () => {
    const darkStorage = useLocalStorage('settings/dark', true)
    const chartDateCountStorage = useLocalStorage(
        'settings/chart_date_count',
        14
    )
    const showChartStorage = useLocalStorage('settings/show_chart', true)
    const activeTransitionsStorage = useLocalStorage(
        'settings/transitions',
        true
    )
    const has18Storage = useLocalStorage('main/has-18', false)
    const themeStorage = useLocalStorage('settings/theme', 'default')

    return {
        darkStorage,
        chartDateCountStorage,
        showChartStorage,
        activeTransitionsStorage,
        has18Storage,
        themeStorage,
    }
}
