import {
    FC,
    PropsWithChildren,
    createContext,
    useContext,
    useState,
} from 'react'
import { IAnime } from '../types/anime'
import { State } from '../types/app'
import { AnimeListEditor } from '../components/Anime/ListEditor'

export const ListEditorContext = createContext<{
    animeState: State<IAnime | undefined>
    showState: State<boolean>
} | null>(null)

export const useListEditor = () => {
    const ctx = useContext(ListEditorContext)
    if (!ctx) throw Error('Context not defined')
    return ctx
}

export const ListEditorProvider: FC<PropsWithChildren> = ({ children }) => {
    const animeState = useState<IAnime>()
    const showState = useState(false)

    return (
        <ListEditorContext.Provider value={{ animeState, showState }}>
            {children}
            <AnimeListEditor />
        </ListEditorContext.Provider>
    )
}
