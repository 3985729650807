import { FC, PropsWithChildren, useCallback, useEffect, useState } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { Container } from './components/Container'
import { Navbar } from './components/Navbar'
import { PageLoader } from './components/UI/Loader'
import { useAppDipatchMods } from './hooks/useTypedDispatch'
import { Router } from './router'
import {
    QueryClient,
    QueryClientProvider,
    useIsFetching,
} from '@tanstack/react-query'
import { ErrorProvider } from './ctx/Error'
import { useLocalStorages } from './hooks/useLocalStorages'
import { useAppSelector } from './hooks/useTypedSelector'
import { Block, BlockContent, BlockTitle } from './components/Block'
import { ListEditorProvider } from './ctx/ListEditor'

const queryClient = new QueryClient({
    defaultOptions: { queries: { refetchOnWindowFocus: false, retry: 0 } },
})

const Fetching: FC<PropsWithChildren> = ({ children }) => {
    const fetching = useIsFetching()

    return (
        <>
            {children}
            {!!fetching && <PageLoader />}
        </>
    )
}

export function Preload() {
    const [loading, setLoading] = useState(true)
    const { fetchAuth } = useAppDipatchMods()
    const { darkStorage, themeStorage, activeTransitionsStorage } =
        useLocalStorages()
    const [dark] = darkStorage
    const [theme] = themeStorage
    const [activeTransitions] = activeTransitionsStorage

    useEffect(() => {
        dark
            ? document.body.classList.add('dark')
            : document.body.classList.remove('dark')
    }, [dark])

    useEffect(() => {
        document.body.classList.add(theme)
        return () => document.body.classList.remove(theme)
    }, [theme])

    useEffect(() => {
        activeTransitions
            ? document.body.classList.remove('no-transitions')
            : document.body.classList.add('no-transitions')
    }, [activeTransitions])

    const init = useCallback(async () => {
        try {
            await fetchAuth()
        } catch (error) {
            console.error(error)
        }

        setLoading(false)
    }, [fetchAuth])

    useEffect(() => {
        init()
    }, [init])

    return loading ? <PageLoader /> : <App />
}

export function App() {
    const auth = useAppSelector((s) => s.auth.user)
    return (
        <BrowserRouter>
            <QueryClientProvider client={queryClient}>
                <Fetching>
                    <ErrorProvider>
                        <ListEditorProvider>
                            <div className="app">
                                <Container>
                                    {!auth ? (
                                        <Block>
                                            <BlockTitle>
                                                Кина не будет, расходимся
                                            </BlockTitle>
                                            <BlockContent>
                                                Произошло то, чего я не ожидал и
                                                не хотел, чтобы с моим проектом,
                                                который я так любил, такое
                                                произошло.
                                                <br />
                                                <br />
                                                В 2019, создавая этот проект, я
                                                хотел, чтобы аниме было
                                                доступным для всех. Я хотел
                                                создать платформу, которая
                                                поможет найти что-то новое для
                                                себя в мире аниме, но, к
                                                сожалению, проект не нашел своих
                                                пользователей.
                                                <br />
                                                <br />
                                                Грустно это осознавать, но все
                                                это время, проект висел мертвым
                                                грузом на моих плечах. Я
                                                использовал его в своих
                                                интересах и продолжу
                                                использовать дальше. В целом, я
                                                не планирую его закрывать,
                                                однако, на какое-то время я
                                                закрываю доступ ко всем
                                                страницам и регистрации, в связи
                                                с очень неприятной ситуацией.
                                                <br />
                                                <br />
                                                27 сентября 2024 года
                                                Роскомнадзор заблокировал мой
                                                проект на территории Российской
                                                Федерации. Я заметил это
                                                случайно, когда пробил сайт в
                                                базе Роскомнадзора. Мне стало
                                                одновременно грустно и больно от
                                                этого.
                                                <br />
                                                <br />
                                                Я потратил более 5 лет на
                                                содержание этого проекта,
                                                который так и не принес
                                                пользователей, а его
                                                заблокировали, не уведомив меня
                                                никак. Я не получал никаких
                                                писем, никаких уведомлений, я
                                                просто получил блокировку.
                                                <br />
                                                <br />
                                                Проект продолжит свою жизнь,
                                                потому что я его люблю и
                                                пользуюсь им сам, но, к
                                                сожалению, я на какое-то время
                                                закрываю доступ ко всем
                                                ресурсам, во избежании
                                                каких-либо потенциальных
                                                проблем. Следовательно, при
                                                наличии аккаунта, можно войти в
                                                свой профиль и получить свой
                                                список с аниме (и не только).
                                            </BlockContent>
                                        </Block>
                                    ) : (
                                        <Router></Router>
                                    )}
                                </Container>
                                <Navbar></Navbar>
                            </div>
                        </ListEditorProvider>
                    </ErrorProvider>
                </Fetching>
            </QueryClientProvider>
        </BrowserRouter>
    )
}
