import classNames from 'classnames'
import { FC } from 'react'
import { useAnime } from '../../hooks/useAnime'
import { useAppSelector } from '../../hooks/useTypedSelector'
import { IAnime } from '../../types/anime'
import { Img } from '.././UI/Img'
import { IconButton } from '../UI/Button/Icon'
import { BaseButton } from '../UI/Button/Base'
import { useListEditor } from '../../ctx/ListEditor'

export const AnimeMini: FC<{ anime: IAnime; active?: boolean }> = ({
    anime,
    active,
}) => {
    const { img, list } = useAnime(anime)
    const auth = useAppSelector((s) => s.auth)
    const {
        animeState: [, setAnime],
        showState: [, setEditor],
    } = useListEditor()
    const showListEditor = () => {
        setAnime(anime)
        setEditor((o) => !o)
    }
    return (
        <div className="anime-mini">
            <BaseButton
                theme="transparent"
                to={`/anime/${anime.id}`}
                active={active}
                className="anime-mini__content"
                contentClassName="anime-mini__link"
            >
                <div className="anime-mini__img">
                    <Img
                        src={img}
                        className={classNames({ bordered: list?.follow })}
                    ></Img>
                </div>
                <div className="anime-mini__title">{anime.title}</div>
                <div
                    className={classNames(
                        `anime-mini__dot`,
                        `anime-mini__dot-${anime.status}`
                    )}
                ></div>
            </BaseButton>
            {!!auth && (
                <IconButton theme="transparent" onClick={showListEditor}>
                    <i className="fa-solid fa-list-check"></i>
                </IconButton>
            )}
        </div>
    )
}
